<template>
  <div data-view>
    <room-detail
      :title="roomDetail.fullname"
      :type="roomDetail.type"
      :description="roomDetail.description"
      :size="roomDetail.size"
      :unit="roomDetail.unit"
      :imageUrl="roomDetail.imageUrl"
      :highlights="roomDetail.highlights"
    />
    <gallery-item
      data-aos="fade-up"
      gallery-id="gallery-neera-green-space"
      :images="roomDetail.roomImages"
    />
    <photo-grid data-aos="fade-up" :photos="roomDetail.galleries" />
    <room-facilities
      data-aos="fade-up"
      :facilities="roomDetail.roomFacilities.facilities"
      :imageUrl="roomDetail.roomFacilities.imageUrl"
    />
    <other-rooms exclude-path="/rooms/lagoon" />
  </div>
</template>

<script>
import OtherRooms from "@/components/OtherRooms.vue";
import RoomDetail from "./components/RoomDetail.vue";
import PhotoGrid from "@/components/PhotoGrid.vue";
import GalleryItem from "@/components/GalleryItem.vue";
import RoomFacilities from "./components/RoomFacilities.vue";
import AOS from "aos";
import config from "@/config";

export default {
  components: {
    OtherRooms,
    RoomDetail,
    PhotoGrid,
    RoomFacilities,
    GalleryItem,
  },
  name: "lagoon Room",
  mounted() {
    AOS.init({
      once: true,
      duration: 2000,
    });
  },
  created() {
    const title = "better living in ‘lagoon’";
    const description =
      "perfect for families or a group of 3–4 friends, our ‘lagoon’ located on the top floor (5th floor) spreads over 70 sqm with 2 double queen-size beds, a private balcony with a view, and a bathtub to unwind or for a fun time with the children.";
    // const titleEl = document.querySelector("head title");
    const mTitleEl = document.querySelector('head meta[name="title"]');
    const descEl = document.querySelector('head meta[name="description"]');
    const twTitleEl = document.querySelector('head meta[name="twitter:title"]');
    const twDescEl = document.querySelector(
      'head meta[name="twitter:description"]'
    );
    const ogTitleEl = document.querySelector('head meta[property="og:title"]');
    const ogDescEl = document.querySelector('head meta[property="og:description"]');
    // titleEl.textContent = title;
    mTitleEl.setAttribute("content", title);
    twTitleEl.setAttribute("content", title);
    ogTitleEl.setAttribute("content", title);
    descEl.setAttribute("content", description);
    twDescEl.setAttribute("content", description);
    ogDescEl.setAttribute("content", description);
  },
  computed: {
    roomDetail() {
      for (var key in config.rooms) {
        console.log(config.rooms[key]); //alerts key's value
        if (key == "lagoon") return config.rooms[key];
      }
      return {};
    },
  },
};
</script>

<style scoped>
.booking_button {
  color: #994e2e;
  border: 1px solid #994e2e;
  border-radius: 10px;
}
</style>
